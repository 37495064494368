<template>
  <div class="transaction">
    <div class="font-weight-bold text-h5 mb-sm-3 py-sm-0 py-4">{{ $t('wallet.tabs')[1] }}</div>
    <v-data-table
      class="d-sm-block d-none"
      hide-default-footer
      fixed-header
      hide-default-header
      :headers="headers"
      :items="data.results"
      item-key="id"
      :items-per-page="-1"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr>
            <th v-for="i in $t('wallet.transTable')" :key="i">{{ i }}</th>
          </tr>
        </thead>
      </template>
      <template v-slot:no-data v-if="!data.pages">
        <div class="d-flex flex-column align-center text-center my-5">
          <img height="300" src="@/assets/icon/noStock.svg" alt="no data" />
          <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-else>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="black--text" style="height: 82px">
            <td class="text-body-1">{{ item.number }}</td>
            <td class="text-body-1 text-capitalize">{{ item.operation }}</td>
            <td class="text-body-1 font-weight-bold">{{ item.amount / 100 }}</td>
            <td class="text-body-1">
              {{
                new Date(item.created_at).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour12: false,
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }}
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:footer>
        <v-divider></v-divider>
        <div class="mt-2 pb-2 px-3 d-flex align-center" v-if="data.pages > 1">
          <v-spacer></v-spacer>
          <v-pagination
            color="white"
            prev-icon="mdi-arrow-left-thin"
            next-icon="mdi-arrow-right-thin"
            @input="getData"
            v-model="page"
            :length="data.pages"
            :total-visible="5"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
    <div class="d-sm-none">
      <div class="d-flex flex-column align-center text-center" v-if="!data.pages">
        <img height="200" src="@/assets/icon/noStock.svg" alt="no data" />
        <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
      </div>
      <div v-else v-for="item in data.results" :key="item.id">
        <div class="gray--text mb-2">
          {{
            new Date(item.created_at).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour12: false,
              hour: 'numeric',
              minute: 'numeric',
            })
          }}
        </div>
        <v-card flat class="d-flex justify-space-between align-center pa-3 black--text rounded-lg mb-2" height="58">
          <div>{{ item.number }}</div>
          <div class="text-capitalize gray--text">{{ item.operation }}</div>
          <div class="font-weight-bold">SAR {{ item.amount / 100 }}</div>
        </v-card>
      </div>
      <div class="mt-2 pb-2 mx-auto" v-if="data.pages > 1">
        <v-pagination
          color="white"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="getData"
          v-model="page"
          :length="data.pages"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      headers: [1, 1, 1, 1],
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.error = [];
      await this.$store.dispatch('getWalletTransactions', this.page).catch((e) => {
        this.error = e.response.status;
        if (e.response.data.error[0] === 'page__out_of_bounds') {
          this.page = 1;
          this.getData();
        }
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.walletTransactions;
    },
  },
};
</script>
