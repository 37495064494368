<template>
  <div class="wallet">
    <div class="text-h4 font-weight-bold mb-4 mt-sm-7">{{ $t('wallet.title') }}</div>
    <v-card
      flat
      class="px-sm-10 px-3 d-flex flex-wrap flex-sm-row flex-column align-sm-center mb-sm-10 mb-7 rounded-lg pt-sm-4 pt-5 pb-4"
      min-height="142"
    >
      <div class="black--text me-3">
        <div class="font-weight-medium">{{ $t('wallet.funds') }}:</div>
        <div class="font-weight-bold text-h4 mt-1">SAR {{ (summary.wallet.total_balance / 100).toFixed(2) }}</div>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex mt-sm-0 mt-4 mx-sm-0 mx-auto">
        <BtnDeposit />
        <v-btn
          @click="modalW = true"
          depressed
          :height="$vuetify.breakpoint.xs ? 48 : 64"
          :width="$vuetify.breakpoint.xs ? 154 : 198"
          class="black ms-sm-5 ms-3"
          dark
        >
          {{ $t('btn.withdrawal') }}
        </v-btn>
      </div>
    </v-card>
    <v-tabs background-color="transparent" v-model="tab">
      <v-tab :to="`/wallet/${tabs[i]}`" v-for="(item, i) in $t('wallet.tabs')" :key="item" class="px-5 text-body-1">{{ item }}</v-tab>
    </v-tabs>
    <v-divider class="mb-7 d-sm-block d-none"></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item class="accent" value="/wallet/overview"><Overview /></v-tab-item>
      <v-tab-item class="accent" value="/wallet/transactions"><Transactions /></v-tab-item>
      <v-tab-item class="accent" value="/wallet/withdrawal"><Withdrawal /></v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="modalW" persistent :max-width="$vuetify.breakpoint.xs ? 400 : 600" width="100%">
      <v-card flat class="pb-sm-10 pt-sm-13 py-6 px-sm-10 px-4">
        <v-btn v-if="step != 3" class="close-btn" @click="modalW = false" icon><v-icon color="black">mdi-close</v-icon></v-btn>
        <v-btn v-if="step == 2" class="close-btn gray--text" style="left: 20px; right: inherit" @click="--step" text>
          <v-icon color="gray">mdi-chevron-left</v-icon>{{ $t('btn.back') }}
        </v-btn>
        <v-window v-model="step">
          <v-window-item :value="1">
            <div class="text-center black--text mx-auto" :style="`max-width: ${$vuetify.breakpoint.xs ? 232 : 384}px`">
              <div class="text-sm-h5 f18 font-weight-black">{{ $t('wallet.withdrawalModal.title') }}</div>
              <div class="gray--text mt-2 text-sm-body-1 text-body-2">
                SAR {{ summary.wallet.withdrawable_balance / 100 }} {{ $t('wallet.withdrawalModal.desc') }}
              </div>
              <v-text-field
                class="field64 center mt-sm-4 mt-2"
                :placeholder="$t('input.amountPla')"
                v-model="amount"
                :error-messages="amountErrors"
                @input="getFee(amount)"
                dense
                outlined
                color="primary"
                height="64"
              ></v-text-field>
              <v-card v-if="amount" flat class="black--text gray lighten-1 pa-4 mb-sm-10 mb-4 d-flex align-center">
                <v-icon class="me-2" color="gray" style="line-height: 24px !important">mdi-alert-circle</v-icon>
                <span class="text-body-2">
                  {{ $t('wallet.withdrawalModal.fee') }} {{ (fee.amount / 100).toFixed(2) }} ({{ fee.percent / 100 }}%)
                </span>
              </v-card>
              <v-card v-else flat :height="$vuetify.breakpoint.xs ? 66 : 56" class="mb-sm-10 mb-4" color="transparent"></v-card>
              <v-btn :disabled="!amount" @click="++step" depressed class="primary" :height="$vuetify.breakpoint.xs ? 48 : 64" block>
                {{ $t('btn.next') }}
              </v-btn>
            </div>
          </v-window-item>
          <v-window-item :value="2">
            <div class="f18 font-weight-bold mb-7 mt-sm-8">{{ $t('wallet.withdrawalModal.detail') }}</div>
            <div class="black--text mb-1">{{ $t('input.iban') }}</div>
            <v-text-field
              class="field56"
              :placeholder="$t('input.ibanPla')"
              v-model="payInfo.iban"
              :error-messages="ibanErrors"
              dense
              outlined
              color="primary"
              height="56"
            ></v-text-field>
            <div class="black--text mb-1">{{ $t('input.accountHoLabel') }}</div>
            <v-text-field
              class="field56"
              :placeholder="$t('input.accountHoLabel')"
              v-model="payInfo.account_holder"
              :error-messages="accountHolderErrors"
              dense
              outlined
              color="primary"
              height="56"
            ></v-text-field>
            <div class="text-sm-center text-end">
              <v-btn
                @click="submitWithdrawal"
                depressed
                class="primary"
                :width="$vuetify.breakpoint.xs ? 162 : 384"
                :height="$vuetify.breakpoint.xs ? 48 : 64"
              >
                {{ $t('btn.submit') }}
              </v-btn>
            </div>
          </v-window-item>
          <v-window-item :value="3">
            <v-card flat :min-height="$vuetify.breakpoint.xs ? 210 : 348" class="d-flex align-center justify-center">
              <div class="text-center mb-sm-0 mb-2">
                <img src="@/assets/icon/processing.svg" alt="processing" />
                <div class="f30 black--text font-weight-bold">{{ $t('wallet.procesTitle') }}</div>
                <div class="gray--text mt-2">{{ $t('wallet.procesDesc') }}</div>
              </div>
            </v-card>
          </v-window-item>
          <v-window-item :value="4">
            <v-card flat tile class="text-center mx-auto" max-width="384">
              <img class="mt-sm-12 mt-4" src="@/assets/icon/check.svg" alt="processing" />
              <div class="f30 black--text font-weight-bold mt-sm-9 mt-4 mb-sm-16 mb-2 pb-sm-5">{{ $t('wallet.congTitle') }}</div>
              <div class="gray--text mt-1 mb-sm-13 mb-6">{{ $t('wallet.congDesc') }}</div>
              <div class="mx-auto" :style="`max-width: ${$vuetify.breakpoint.xs ? 232 : 384}px`">
                <v-btn @click="modalW = false" depressed class="primary" :height="$vuetify.breakpoint.xs ? 48 : 64" block>
                  {{ $t('btn.nice') }}
                </v-btn>
              </div>
            </v-card>
          </v-window-item>
          <v-window-item :value="5">
            <v-card flat tile class="text-center mx-auto" max-width="384">
              <img class="mt-sm-12 mt-4" src="@/assets/icon/check.svg" alt="processing" />
              <div class="f30 black--text font-weight-bold mt-sm-9 mt-4 mb-sm-16 mb-2 pb-sm-5">{{ $t('wallet.requestTitle') }}</div>
              <div class="gray--text mt-1 mb-sm-8 mb-6">{{ $t('wallet.requestDesc') }}</div>
              <div class="mx-auto" :style="`max-width: ${$vuetify.breakpoint.xs ? 232 : 384}px`">
                <v-btn @click="modalW = false" depressed class="primary" :height="$vuetify.breakpoint.xs ? 48 : 64" block>
                  {{ $t('btn.nice') }}
                </v-btn>
              </div>
            </v-card>
          </v-window-item>
        </v-window>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BtnDeposit from '../components/wallet/BtnDeposit.vue';
import Overview from '../components/wallet/Overview.vue';
import Transactions from '../components/wallet/Transactions.vue';
import Withdrawal from '../components/wallet/Withdrawal.vue';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    BtnDeposit,
    Overview,
    Transactions,
    Withdrawal,
  },
  data() {
    return {
      modalW: false,
      step: 1,
      amount: '',
      payInfo: {},
      tab: this.$route.path,
      tabs: ['overview', 'transactions', 'withdrawal'],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getWalletSummary');
    this.$store.dispatch('getSummary');
  },
  methods: {
    async submitWithdrawal() {
      const data = new Object();
      data.amount = this.amount * 100;
      data.iban_number = this.payInfo.iban;
      data.account_holder = this.payInfo.account_holder;
      await this.$store
        .dispatch('submitWalletWithdrawal', data)
        .then((res) => {
          if (res.data.request_status == 'successful') {
            this.step = 4;
          } else if (res.data.request_status == 'submitted') {
            this.step = 5;
          } else {
            ++this.step;
          }
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.error.find((item) => item == 'amount__required') || this.error.find((item) => item == 'amount__invalid')
            ? (this.step = 1)
            : '';
        });
    },
    async getFee(i) {
      await this.$store.dispatch('getWalletWithdrawalFee', i).catch((e) => {
        this.error = e.response.data.error;
      });
    },
  },
  watch: {
    modalW() {
      this.amount = '';
      this.step = 1;
      this.payInfo = {
        checkbox: true,
      };
    },
  },
  computed: {
    summary() {
      return this.$store.getters.summaryList;
    },
    fee() {
      return this.$store.getters.walletWithdrawalFee;
    },
    amountErrors() {
      const errors = [];
      this.error.find((item) => item == 'amount__required') && errors.push(this.$t('inputError.amountRequired'));
      this.error.find((item) => item == 'amount__invalid') && errors.push(this.$t('inputError.amountInvalid'));
      this.error.find((item) => item == 'action__limit_exceeded') && errors.push(this.$t('inputError.limitExceededAmount'));
      this.error.find((item) => item == 'amount__too_big') && errors.push(this.$t('inputError.withBalance'));
      this.amount > this.summary.wallet.withdrawable_balance / 100 && errors.push(this.$t('inputError.withBalance'));
      return errors;
    },
    ibanErrors() {
      const errors = [];
      this.error.find((item) => item == 'iban_number__required') && errors.push(this.$t('inputError.ibanRequired'));
      this.error.find((item) => item == 'iban_number__invalid') && errors.push(this.$t('inputError.ibanInvalid'));
      return errors;
    },
    accountHolderErrors() {
      const errors = [];
      this.error.find((item) => item == 'account_holder__required') && errors.push(this.$t('inputError.accountHolderRequired'));
      this.error.find((item) => item == 'account_holder__invalid') && errors.push(this.$t('inputError.accountHolderInvalid'));
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setWallet');
    this.$store.dispatch('setSummary');
  },
};
</script>

<style lang="scss">
#app .wallet {
  .ct-series-a {
    .ct-bar {
      stroke: var(--v-black-base);
    }
  }
  .ct-series-b {
    .ct-bar {
      stroke: var(--v-primary-base);
    }
  }
  .v-divider {
    margin-top: -2px;
    height: 2px;
    border-width: 1px;
    border-color: #ebeff4;
  }
  td[colspan]:hover {
    background: white !important;
  }
  @media screen and (max-width: 599px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
</style>
