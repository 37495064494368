<template>
  <div class="overview">
    <div class="font-weight-bold text-h5 py-sm-0 py-4">{{ $t('wallet.tabs')[0] }}</div>
    <v-card flat class="mt-3 pa-sm-7 rounded-lg" :class="$vuetify.breakpoint.xs ? 'accent' : ''">
      <div class="d-flex align-center mb-3">
        <div class="d-md-flex d-none align-center">
          <v-card flat class="black me-3" height="24" width="24"></v-card>
          <div class="me-10">{{ $t('wallet.depositTotal') }}</div>
          <v-card flat class="primary me-3" height="24" width="24"></v-card>
          <div>{{ $t('wallet.withdrawalsTotal') }}</div>
        </div>
        <v-spacer></v-spacer>
        <div
          class="gray lighten-1 rounded-lg ms-sm-7"
          style="padding: 6px; height: 48px"
          :class="$vuetify.breakpoint.xs ? 'width100 d-flex justify-space-around' : ''"
        >
          <span
            :class="period == item ? 'rounded-lg white' : ''"
            class="link d-inline-block mx-2 text-center py-2 px-5 text-uppercase"
            @click="getStatistics(item)"
            v-for="item in periodList"
            :key="item"
          >
            {{ item }}
          </span>
        </div>
      </div>
      <vue-chartist type="Bar" :data="bar.data" :options="bar.options"></vue-chartist>
      <div class="d-md-none d-flex align-center mt-4">
        <v-card flat class="black me-3" :height="$vuetify.breakpoint.xs ? 16 : 24" :width="$vuetify.breakpoint.xs ? 16 : 24"></v-card>
        <div class="me-10 text-sm-body-1 text-body-2">{{ $t('wallet.depositTotal') }}</div>
        <v-card flat class="primary me-3" :height="$vuetify.breakpoint.xs ? 16 : 24" :width="$vuetify.breakpoint.xs ? 16 : 24"></v-card>
        <div class="text-sm-body-1 text-body-2">{{ $t('wallet.withdrawalsTotal') }}</div>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueChartist from 'v-chartist';

export default {
  components: {
    'vue-chartist': VueChartist,
  },
  data() {
    return {
      period: '3y',
      periodList: ['1y', '3y', '10y'],
      bar: {
        data: {
          labels: [],
          series: [],
        },
        options: {
          seriesBarDistance: 15,
          fullWidth: true,
          axisX: {
            showGrid: true,
          },
          axisY: {
            offset: 80,
          },
          height: '495px',
          // plugins: [Chartist.plugins.tooltip()]
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getWalletStatistics', this.period);
  },
  methods: {
    getStatistics(i) {
      this.period = i;
      this.$store.dispatch('getWalletStatistics', this.period);
    },
  },
  watch: {
    statistics(val) {
      if (val.series) {
        this.bar.data.series = this.statistics.series.map((i) => i.map((j) => j / 100));
        this.bar.data.labels = this.statistics.labels;
      }
    },
  },
  computed: {
    statistics() {
      return this.$store.getters.walletStatistics;
    },
  },
};
</script>
